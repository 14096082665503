import React from "react";
import styled from "styled-components/macro";
import { Route, Switch, Link } from "react-router-dom";
import { Image } from "../../../../components/Image";
import { Span } from "../../../../components/typography";
import SearchInput from "./SearchInput";

const CategoryOptionWrapNoStyle = ({ children, ...otherProps }) => (
  <div {...otherProps}>{children}</div>
);
const CategoryOptionWrap = styled(CategoryOptionWrapNoStyle)`
  overflow: hidden;
  padding-top: 5px;

  @media (max-width: 767px) {
    border-bottom: 0;
  }
`;
const BorderBottom = styled.div`
  background: ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-top: 5px solid ${({ theme }) => theme.colors.colorBlue};
  display: block;
  height: 10px;
  width: 100%;

  @media (max-width: 767px) {
    height: 0px;
  }
`;
const CategoryLinkNoStyle = ({
  children,
  to,
  title,
  active,
  returPantOption,
  leveringOption,
  flaskerOption,
  fadoelOption,
  kulsyreOption,
  ...otherProps
}) => (
  <Link
    to={to}
    title={title}
    returPantOption={returPantOption}
    leveringOption={leveringOption}
    flaskerOption={flaskerOption}
    fadoelOption={fadoelOption}
    kulsyreOption={kulsyreOption}
    {...otherProps}
  >
    {children}
  </Link>
);
const CategoryLink = styled(CategoryLinkNoStyle)`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s ease;
  background-color:  ${({ theme, active }) =>
    active ? theme.colors.colorGreyLightAlt : theme.colors.colorBlue};
  border: 5px solid ${({ theme, active }) =>
    active ? theme.colors.colorBlue : "none"};
  border-bottom: 0px;
  color:  ${({ theme, active }) =>
    active ? theme.colors.colorGreyDark : theme.colors.colorWhite};
  width: ${({ returPantOption, leveringOption }) =>
    returPantOption || leveringOption ? "auto" : "220px"};
  height: ${({ returPantOption, leveringOption }) =>
    returPantOption || leveringOption ? "auto" : "132px"};
  padding: ${({ active }) => (active ? "5px" : "10px")};
  position: relative;
  margin-right: 10px;
  border-top-left-radius: ${({ returPantOption, leveringOption }) =>
    returPantOption || leveringOption ? "10px" : "20px"};
  border-top-right-radius: ${({ returPantOption, leveringOption }) =>
    returPantOption || leveringOption ? "10px" : "20px"};
  box-shadow: ${({ active }) =>
    active ? "" : "0 0 30px rgba(0,0,0,0.3) inset"};
  opacity: ${({ active }) => (active ? "1" : "0.7")};
  top: ${({ returPantOption, leveringOption }) =>
    returPantOption || leveringOption ? "8px" : "7px"};
  margin-bottom: 2px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    background-color:  ${({ theme, active }) =>
      active ? theme.colors.colorGreyLightAlt : theme.colors.colorBlue};
    height: 5px;
    left: 0;
    bottom: ${({ active }) => (active ? "-5px" : "0")};
  }

  &:hover {
    opacity: 1;
  }
  @media (max-width: 1199px) {
    width: ${({ returPantOption, leveringOption }) =>
      returPantOption || leveringOption ? "auto" : "170px"};
  }
  @media (max-width: 991px) {
    width: ${({ returPantOption, leveringOption }) =>
      returPantOption || leveringOption ? "auto" : "150px"};
    margin-right: 5px;
    top: ${({ returPantOption, leveringOption }) =>
      returPantOption || leveringOption ? "12px" : ""};

    & span {
      font-size: ${({ returPantOption, leveringOption }) =>
        returPantOption || leveringOption ? "14px" : "inherit"};
      padding-right: ${({ returPantOption, leveringOption }) =>
        returPantOption || leveringOption ? "0" : ""};
      padding-left: ${({ returPantOption, leveringOption }) =>
        returPantOption || leveringOption ? "0" : ""};
    }
  }
  
  @media (max-width: 767px) {
  ${({ flaskerOption, fadoelOption, returPantOption, leveringOption }) => {
    if (flaskerOption) {
      return `
        width: calc(50% - 25px);
      `;
    }
    if (fadoelOption) {
      return `
        margin-right: 0px;
        width: calc(50% - 20px);
      `;
    }
    if (returPantOption || leveringOption) {
      return `
        display: none;
      `;
    }
    return "";
  }}

  ${({ kulsyreOption, active }) => {
    if (kulsyreOption) {
      return `
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(100% - 20px);
        height: 40px;
        margin-right: 0px;
        margin-top: -7px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: 5px solid #4A728A;
        opacity: 1;
        background: ${active ? "" : "#809cad"};
        box-shadow: ${active ? "" : "0 0 30px rgba(0,0,0,0.2) inset"};
        border-left: 0px;
        border-right: 0px;
        border-bottom: 5px solid #4A728A;
        padding: 5px 10px;

        & div {
          width: 50px;
        }

        &:after {
          display: none;
        }
      `;
    }
    return "";
  }}
`;

const SpanTitle = styled(Span)`
  font-size: 16px;
  font-weight: bold;
  display: block;
  text-align: center;
  padding: 10px;

  @media (max-width: 1199px) {
    font-size: 15px;
  }

  @media (max-width: 991px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
`;

const SpanLongTitle = styled(SpanTitle)`
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
`;

const SpanShortTitle = styled(SpanTitle)`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CategoryOption = ({
  id,
  active,
  title,
  shortTitle,
  to,
  image,
  returPantOption,
  leveringOption,
  kulsyreOption,
  flaskerOption,
  fadoelOption,
}) => (
  <React.Fragment>
    <CategoryLink
      id={id}
      to={to}
      returPantOption={returPantOption}
      leveringOption={leveringOption}
      flaskerOption={flaskerOption}
      fadoelOption={fadoelOption}
      kulsyreOption={kulsyreOption}
      disabled={active}
      active={active}
    >
      {!returPantOption && !leveringOption && (
        <ImageDiv>
          <Image name={image} />
        </ImageDiv>
      )}
      <SpanLongTitle>{title}</SpanLongTitle>
      <SpanShortTitle>{shortTitle}</SpanShortTitle>
    </CategoryLink>
  </React.Fragment>
);

const CategorySelector = ({ activeIndex }) => (
  <CategoryOptionWrap>
    <CategoryOption
      id="overCat_flasker-og-daaser"
      flaskerOption
      active={activeIndex === 0}
      title="Flasker &amp; dåser"
      shortTitle="Flasker/Dåser"
      to="/bestil/flasker-og-daaser"
      image="bestil.flaskerogdaaser1.png"
    />
    <CategoryOption
      id="overCat_fadoel-og-anlaeg"
      fadoelOption
      active={activeIndex === 1}
      title="Fadøl &amp; anlæg"
      shortTitle="Fadøl/Anlæg"
      to="/bestil/fadoel-og-anlaeg"
      image="bestil.fadoeloganlaeg1.png"
    />
    <CategoryOption
      id="overCat_kulsyre"
      kulsyreOption
      active={activeIndex === 4}
      title="Kulsyre"
      shortTitle="Kulsyre"
      to="/bestil/kulsyre"
      image="bestil.kulsyre.png"
    />
    <SearchInput />
    <BorderBottom />
  </CategoryOptionWrap>
);

const createCategorySelector = (activeIndex) => (props) => (
  <CategorySelector activeIndex={activeIndex} {...props} />
);

export default () => (
  <Switch>
    <Route
      path="/bestil/flasker-og-daaser"
      render={createCategorySelector(0)}
    />
    <Route path="/bestil/fadoel-og-anlaeg" render={createCategorySelector(1)} />
    <Route path="/bestil/returpant" render={createCategorySelector(3)} />
    <Route path="/bestil/kulsyre" render={createCategorySelector(4)} />
    <Route render={createCategorySelector(-1)} />
  </Switch>
);
